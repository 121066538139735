'use client'


// import { NovaContext } from 'src/components/Nova'
// import { Call } from 'src/icons/Call'
import { View360 } from '../icons/View360'


// 
import React, { useEffect } from 'react'
//import { CallButton } from './CallButton'

import backdau from '../images/tieu-de-bg.jpg'

// ../../../images/tieu-de-bg.jpg
//C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\Header\index.jsx


//cilent_nova_socket/src/components/Nova/Header/index.jsx

export const NovaHeader = () => {


  const pathname = window.location.pathname;
  //console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
  //console.log(pathname);
  // const { setOpenForm } = React.useContext(NovaContext)


  useEffect(() => {
    // document.getElementById('NOVA_HEADER')?.scrollIntoView({ behavior: 'smooth' })
  }, [pathname])

  return (
    <section id="NOVA_HEADER">
      <div className="w-full relative">
        {/* <div className="absolute h-1/3 left-0 right-0 bottom-0 bg-blue-500 bg-opacity-20" /> */}
        {/*   <div style={{height : '100px'}} >
          </div> */}

        {/*  <img src={backdau} width={'100%'} alt="" className="brightness-95" /> */}
        {/* md:top-24 text-gray-950 md:text-emerald-400 */}
        {/* md:absolute w-full top-24  md:left-1/2 transform md:-translate-x-1/2 flex flex-col items-center */}



        <div className='relative h-80 lg:h-96 md:h-80 sm : h-72' >
          {/* font-thena text-5xl sm:text-7xl lg:text-8xl font-bold text-shadow relative right-8 */}
          <h2 className="font-thena text-5xl sm:text-7xl lg:text-8xl font-bold text-shadow top-24 relative items-center" style={{ color: 'red' }}>
            Du lịch Trung Thu
          </h2>
          <h3 className="font-thena text-3xl sm:text-5xl lg:text-6xl font-bold text-shadow top-24 relative  items-center" style={{ color: 'blue' }}>
            cho Thuê Xe Du lịch
          </h3>

          <h3 className="font-thena text-2xl sm:text-3xl lg:text-4xl font-bold text-shadow top-24 relative items-center" style={{ color: 'green' }}>
            Từ 4-7-16 chỗ
          </h3>




          <a
            href="https://maps.app.goo.gl/BM2hd6sRPE35gooR6"
            target="_blank"
            rel="noreferrer"

          >
            <div className=" text-x sm:text-3xl lg:text-4xl font-bold text-shadow top-24 relative items-center" style={{ color: 'green' }}>


              <View360 className="inline-block" /> Bình châu - Xuyên Mộc - BRVT

              {/*        <CallButton /> */}

            </div>
          </a>


        </div>





      </div>
    </section>
  )
}
