'use client'

import React from 'react'

import logoanh from '../images/logo.png'
import { useState, useEffect, useContext } from "react";

import './Topheader.css'


//D:\cloud_save\website\web_taxi\react_ins\src\Tophear\index.jsx
// ../../../images/logo.png
//C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\TopHeader\index.jsx

export const NovaTopHeader = () => {



  return (
    <div className="fixed top-0 left-0 z-50 w-full bg-gradient-to-r from-gray-400 to-blue-400 shadow-xl px-4 md:px-0">
      {/* fixed top-0 left-0 z-50 w-full bg-gradient-to-r from-green-400 to-blue-400 shadow-xl px-4 md:px-0 */}
      <div className="max-w-screen-md m-auto flex justify-between items-center">
        <a href="tel:0909727290" rel="">
          <div className="flex gap-3 p-1">
            <div className="w-8 md:w-10">
              <svg
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                // fill="rgba(255,87,34,1)"
                className="fill-yellow-400"
              >
                <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
              </svg>
            </div>
            <div className="font-bold text-white ">
              <h1 className="text-sm md:text-2xl">0909 727 290</h1>
              <h3 className="text-sm md:text-xl  text-yellow-400 font-bold">Hotline</h3>
              {/* text-base md:text-xl */}
            </div>
          </div>
        </a>





      {/*   <Link to="/"> */}
          <img src={logoanh} alt="" className="drop-shadow-2xl w-16 h-16" />
      {/*   </Link> */}
      </div>
    </div>
  )
}

