import logo from './logo.svg';
import './App.css';

import { NovaTopHeader } from './Tophear'
import { NovaHeader } from './Head'

import { default as RCImageGallery } from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageView from './comview/ImageView'; 


// dòng phía dưới nằm dưới tất cả dòng import
const ImageGalleryCmp = RCImageGallery


function App() {

// lưu ý folder img nằm trong thư mục public của dự án :
let n_img = 10;
let galleries = [];
let ext_img = ".jpg";
let url_img = "/img/";
for(var ig = 0; ig < n_img ; ig ++){

  let p1 = {original : url_img+ig.toString()+ext_img, thumbnail : url_img+ig.toString()+ext_img }
  galleries.push(p1);
}
let props = {
  items : galleries, 
}


  return (
    <div className="App">

<NovaTopHeader />
<NovaHeader />
<ImageGalleryCmp {...props} autoPlay />
<ImageView imageUrl="/img/gia.png" />
<section className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 text-left">
            Chào mừng đến với chúng tôi!
          </h2>
          <p className="text-gray-600 mt-2 text-left">
            Chúng tôi là dịch vụ taxi du lịch hàng đầu tại Bà Rịa Vũng Tàu, luôn sẵn sàng đưa bạn đến những địa điểm du lịch tuyệt vời xung quanh Bình Châu và Xuyên Mộc.
          </p>
          <p className="text-gray-600 mt-2 text-left">
            Khám phá vùng đất đẹp và thú vị này cùng chúng tôi!
          </p>
        </section>
        <section className="bg-white shadow-md p-6 rounded-lg mt-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 text-left">
            Dịch vụ của chúng tôi
          </h2>
          <ul className="list-disc list-inside text-gray-600 mt-2">
            <li className="text-left">Xe taxi mới, tiện nghi và an toàn</li>
            <li className="text-left">Tài xế chuyên nghiệp và hiểu rõ vùng đất</li>
            <li className="text-left">Giá cả cạnh tranh và không có phí ẩn</li>
            <li className="text-left">Dịch vụ 24/7, luôn sẵn sàng phục vụ bạn</li>
          </ul>
        </section>
        <section className="bg-white shadow-md p-6 rounded-lg mt-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 text-left">
            Khám phá các điểm đến
          </h2>
          <ul className="list-disc list-inside text-gray-600 mt-2">
            <li className="text-left">Suối nước nóng Bình Châu</li>
            <li className="text-left">Du lịch Nova World</li>
            <li className="text-left">Du lịch Hồ Cóc</li>
            <li className="text-left">Du lịch Hồ Tràm</li>
            <li className="text-left">Du lịch Vũng Tàu</li>
          </ul>
        </section>

    </div>
  );
}

export default App;
