import React from 'react';

// Định nghĩa một component tên là ImageView
function ImageView(props) {
  return (
    <div>
      {/* Sử dụng thẻ <img> để hiển thị tấm ảnh */}
      <img src={props.imageUrl} alt="Ảnh" />
    </div>
  );
}

export default ImageView;